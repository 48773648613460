.custom-modal {
  margin-top: 100px;

  .header {
    color: white;
    background-color: black;

    .close-btn {
      background-color: transparent;
      font-size: 22px;
      color: white !important;
      box-shadow: 0 0 0 0;
    }
  }

  .row-data {
    border-radius: 5px;

    &:hover {
      border: 1px solid #a7a6a6;
    }
  }

  .gray {
    background-color: #f6f6f6;
  }

  h4 {
    margin: 0;
    font-size: 16px;
  }

  .border-bottom {
    border-bottom: #f6f6f6 1px solid;
    padding-bottom: 13px;
    margin-bottom: 10px;
  }

  .change-block {
    // margin: 10px 0 10px 0 default;

    &.center {
      margin: 10px auto;
    }

    h4 {
      margin: 10px 0;
    }

    // .rb {
    //     margin: 25px 0 0 7px;
    // }
  }

  .grant-deny-title div {
    text-align: center;
    font-size: 16px;
  }
}
