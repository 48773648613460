.fill-out {
  max-height: calc(100vh - 152px);
  // max-height: 100vh;
  display: block;
  overflow: hidden;
}

.right {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.fill-in {
  max-height: calc(100vh - 110px);
  height: 100%;
  display: block;
  overflow: auto;
}

.right-side {
  // padding-top: 1rem;
  border-left: 1px solid #e6e6e6;
}
