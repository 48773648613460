.fill-out {
  // max-height: calc(100vh - 110px);
  max-height: 100vh;
  display: block;
  overflow: hidden;
}

.col-11 {
  padding: 0.5rem;
}

.search-text {
  width: 100%;
  border: 1px solid black;
}

.fill-in-search {
  max-height: calc(100vh - 320px);
  display: block;
  overflow: auto;
}

.right-side-row {
  margin-left: -0.35rem;
}

.border-bottom-light {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 10px;
}

.accordionContent {
  font-size: 100%;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  vertical-align: baseline;
  background: transparent;
  color: var(--eds-color-text-default);
  padding: 5px;
  margin: 4px;
  display: flex;
  justify-content: space-between;
}

.toggle-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 40px;
  height: 20px;
  border-radius: 10px;
  background-color: #ccc;
  position: relative;
  cursor: pointer;
  outline: none;
}

.toggle-checkbox:checked {
  background-color: #333e51;
}

.toggle-checkbox::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;
  transition: all 0.3s;
}

.toggle-checkbox:checked::before {
  transform: translateX(20px);
}

.auto-select-checkbox {
  padding: 2px 0 10px 10px;
  width: 100%;

  input[type="checkbox"] {
    -webkit-appearance: none;
    top: 5px;
  }
}

.border-bottom-light {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 10px;
}

input[type="checkbox"] {
  accent-color: grey;
}

.accordionContent:hover {
  background-color: lightgrey;
  cursor: pointer;
}

.triStateCheckbox .p-checkbox .p-checkbox-box.p-highlight,
.triStateCheckbox .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #333e51;
  background: #333e51;
  .pi-times {
    color: #333e51 !important;
  }
}

.triStateCheckbox .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  color: #ffffff;
  background: #343637;
  .pi-times {
    color: #333e51 !important;
  }
}

.triStateCheckbox .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a8a8a8;
  border-color: #111112;
}

.triStateCheckbox .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #111112;
  .pi-times {
    color: #333e51 !important;
  }
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
