.fill-in {
  height: 100%;
  width: 100%;
}

// New Table

.table {
  thead {
    position: sticky;
    top: 0px;
    margin: 0 0 0 0;

    .corner-col {
      position: sticky;
      left: 0px;
    }
  }

  .corner-col {
    background-color: white;
    padding: 0px;
  }

  th:first-child,
  td:first-child {
    position: sticky;
    left: 0px;
    z-index: 1;
  }

  tr > th {
    background-color: #f1f1f1;
  }

  tr:nth-child(even) > td {
    background-color: #ededed;
  }

  tr:nth-child(odd) > td {
    background-color: #e2e2e2;
  }

  .column {
    padding: 5px;
    min-width: 250px;
  }

  .btn-icon {
    cursor: pointer;
  }

  .flextype-col {
    top: 72.5px;
    position: sticky;
    left: 0px;
    margin: 0;
    padding: 0;
    padding: 2px 2px 2px 8px;

    p {
      line-height: 1.1;
      margin: 0;
    }
  }

  .crud-display {
    text-align: center;
    cursor: pointer;
  }
}

.table > thead:first-child > tr:first-child > th:first-child {
  width: 250px;
}

.table > tbody > tr > td:first-child {
  width: 250px;
}

.custom-button {
  display: flex;
  flex-direction: column;

  height: 100%;
  max-width: 150px;
  width: 100%;

  padding: inherit;
  margin: 0;
  background-color: rgb(240, 240, 240);
  border: none;
}

.pos {
  color: darkgreen;
}

.neg {
  color: darkred;
}
