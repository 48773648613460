.right {
  text-align: right;
  border-right: black 1px solid;
}

.center {
  text-align: center;
}

.btn-lg {
  margin: 0 30px 0 0;
}

.bump-right {
  margin: 0px 0 0 20px;
}

.btn-sm {
  margin: 6px 0 0 10px;
}
