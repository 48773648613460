.env-btn-div {
  margin-top: 4px;
  margin-right: 5px;
}

.p-dialog {
  background-color: #fff;
  margin: 10px !important;
  border-radius: 6px !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;

  .p-dialog-header {
    background-color: black !important;
    color: #fff !important;
    padding: 1rem !important;

    .p-dialog-title {
      font-weight: 600;
      font-size: 1.75rem !important;
    }
  }

  .p-dialog-content {
    padding: 0 1.5rem 1.5rem 1.5rem;

    .confirmation-content {
      margin-top: 30px;
      background: #ffffff;
      color: #495057;
      padding: 0 1.5rem 1rem 0rem !important;

      .pi {
        font-family: primeicons;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        display: inline-block;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      .p-confirm-dialog-message {
        margin-left: 1.5rem;
        font-size: 1rem;
        position: relative;
        top: -5px;
      }
    }
  }

  .dialog-footer {
    display: flex;
    // justify-content: end;
    margin-top: 20px;
    padding: 0 1.5rem 0rem 1.5rem;

    .p-button {
      color: #ffffff !important;
      background: #3b82f6;
      border: 1px solid #3b82f6;
      padding: 0.75rem 1.25rem;
      font-size: 1rem;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      border-radius: 6px;

      &:enabled:hover {
        background: #3b82f6;
      }

      .p-button-icon-left {
        margin-right: 0.5rem;
      }

      .pi {
        font-family: primeicons;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        display: inline-block;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    .btn-success {
      background-color: #198754;
      border: 1px solid #198754;

      &:enabled:hover {
        background-color: #198754;
        border: 1px solid #198754;
      }
    }

    .btn-info {
      background-color: #0dcaf0;
      border: 1px solid #0dcaf0;

      &:enabled:hover {
        background-color: #0dcaf0;
        border: 1px solid #0dcaf0;
      }
    }
  }
}

.dialog-demo .p-button {
  margin: 0 0.5rem 0 0;
  min-width: 10rem;
}

.dialog-demo p {
  margin: 0;
  line-height: 1.5;
}

.dialog-demo .confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-demo .p-dialog .p-button {
  min-width: 6rem;
}
